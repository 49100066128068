import React from 'react'
import { useIntl } from 'react-intl'

import { SEO, Contact } from '../components'

const Page = () => {
  const { formatMessage: f } = useIntl()

  return (
    <>
      <SEO title={f({ id: 'navigation.kontakt' })} />
      <section className='py-8 px-4 h-full flex items-center'>
        <div className='mx-auto'>
          <Contact />
        </div>
      </section>
    </>
  )
}

export default Page
